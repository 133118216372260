<template>
	<b-form-group :label="label">
		<b-form-input :value="value" :type="type" :placeholder="placeholder" @input="onInput" :formatter="formatter" />
	</b-form-group>
</template>

<script>
export default {
	props: {
		label: { required: true },
		value: { required: true },
		placeholder: { required: true },
		type: { required: false, default: 'text' }
	},

	methods: {
		formatter(value) {
			if (this.type == 'tel') 
				return value.replace(/[^+\-\d\s]/, '')

			return value
		},

		onInput(inputValue) {
			this.$emit('input', inputValue)
		}
	}
}
</script>
